import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import { ApiClient } from '../../services/ApiService';

const firebaseConfig = {
  apiKey: "AIzaSyBR4uGycSQkL_ig0p4n7wX2Jon5nPom9NU",
  authDomain: "thcentral-8f351.firebaseapp.com",
  projectId: "thcentral-8f351",
  storageBucket: "thcentral-8f351.firebasestorage.app",
  messagingSenderId: "617623572002",
  appId: "1:617623572002:web:800b9e2d8380649aa2447b"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export function authModal(apiClient: ApiClient) {
  return {
    isSignUp: false,
    email: '',
    password: '',
    error: '',
    pendingAction: null,

    async handleEmailSignIn() {
      try {
        const result = await signInWithEmailAndPassword(auth, this.email, this.password);
        const idToken = await result.user.getIdToken();
        await apiClient.exchangeToken(idToken);
        if (this.pendingAction) {
          this.$store.authModal.pendingAction();
        }

        this.close();
      } catch (error) {
        console.error(error)
        this.error = error.message;
      }
    },

    async handleEmailSignUp() {
      try {
        const result = await createUserWithEmailAndPassword(auth, this.email, this.password);
        const idToken = await result.user.getIdToken();
        await apiClient.exchangeToken(idToken);
        if (this.pendingAction) {
          this.$store.authModal.pendingAction();
        }

        this.close();
      } catch (error) {
        console.error(error)
        this.error = error.message;
      }
    },

    async handleGoogleSignIn() {
      try {
        const result = await signInWithPopup(auth, googleProvider);
        const idToken = await result.user.getIdToken();
        await apiClient.exchangeToken(idToken);
        if (this.$store.authModal.pendingAction) {
          this.$store.authModal.pendingAction();
        }

        this.close();
      } catch (error) {
        console.error(error)
        this.error = error.message;
      }
    },

    open(pendingAction = null) {
      this.$store.authModal.isOpen = true;
      this.$store.authModal.pendingAction = pendingAction;
    },

    close() {
      this.$store.authModal.isOpen = false;
      this.$store.authModal.pendingAction = null;

      this.error = '';
      this.email = '';
      this.password = '';
    }
  };
}
