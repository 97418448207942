import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import '../components/UploadForm/UploadForm';
import { initIndexPage } from '../components/IndexPage/IndexPage';
import { getCommonAlpineData } from '../utils/common';
import { ApiClient } from '../services/ApiService';
import { API_BASE_URL } from '../constants';
import { authModal } from '../components/AuthModal/AuthModal';

// Initialize Alpine plugins
Alpine.plugin(intersect);
(window as typeof window & { Alpine: typeof Alpine }).Alpine = Alpine;

document.addEventListener('alpine:init', () => {
  const apiClient = new ApiClient({
      baseUrl: API_BASE_URL,
      // Optional custom error handler
      onError: (error) => {
        console.error('Feed client error:', error);
      }
    })

  getCommonAlpineData(apiClient);

  Alpine.data('authModal', function() {
    return authModal(apiClient)
  })

  const feedClient = new ApiClient({
    baseUrl: API_BASE_URL,
    // Optional custom error handler
    onError: (error) => {
      console.error('Feed client error:', error);
    }
  });

  // Register main app component
  Alpine.data('app', function() {
    return {
      ...initIndexPage(feedClient),

      // Initialize
      async init() {
        this.initIndexPage()
        this.initCommon()
      },

    };
  });

});

Alpine.start();

