import { AdRequest, CuratorRequest, RequestModelRequest } from "../types/types";

interface NotificationSubscription {
  email: string;
  creatorId: string;
}

// Types for API responses and requests
interface StreamingPaths {
  hls: string;
  dash: string;
}

interface VideoStreamingPaths {
  portrait: StreamingPaths;
  landscape: StreamingPaths;
}

interface Post {
  title: string;
  id: string;
  thumbnail_file_path: string;
  description: string;

  // Deprecated
  landscape_video_file_path?: string;
  portrait_video_file_path?: string;

  // HLS/DASH streaming paths
  streaming_paths: VideoStreamingPaths;

  tags: string[];
  creator_id: string;
  created_at: string;
  source: {
    Type: 'youtube' | 'x-twitter' | 'original' | 'instagram';
    URL: string;
  };
  duration_seconds: number;
}

interface SearchResult {
  posts: Post[];
  hasMore: boolean;
  nextPage: number;
}

interface SkipRewindAction {
  action_type: 'skip' | 'rewind'; // Indicates whether the action is a skip or rewind
  start_time: number;            // Start time of the action in seconds
  end_time: number;              // End time of the action in seconds
}

interface ViewRequest {
  post_id: string;                  // ID of the post being viewed
  watch_time_seconds: number;       // Total watch time in seconds
  feed_position: number;            // Position of the video in the feed
  feed_source: string;              // Source of the video feed (e.g., "main_feed", "profile")
  creator_id: string;               // ID of the content creator
  skips_and_rewinds?: SkipRewindAction[]; // Optional array of skip and rewind actions
}

interface TelegramSubscriptionStatus {
  is_subscribed: boolean;
  send_hour?: number;
  send_minute?: number;
  signup_at?: string;
}

interface TelegramStartLink {
  start_link: string;
}

interface UserFollows {
  follows: string[];
}

interface FeedClientConfig {
  baseUrl: string;
  cookieDomain?: string;
  onError?: (error: Error) => void;
}

class FeedClient {
  private baseUrl: string;
  private cookieDomain: string;
  private onError: (error: Error) => void;

  constructor(config: FeedClientConfig) {
    this.baseUrl = config.baseUrl.replace(/\/$/, ''); // Remove trailing slash if present
    this.cookieDomain = config.cookieDomain || window.location.hostname;
    this.onError = config.onError || console.error;
  }

  /**
   * Get feed posts for the current user
   */
  async getFeed(refresh: boolean, v2Recommendations: boolean): Promise<SearchResult> {
    try {
      const response = await fetch(`${this.baseUrl}/feed${v2Recommendations ? '?rv=1' : refresh ? '?rf=1' : ''}`, {
        method: 'GET',
        credentials: 'include', // Include cookies in request
        headers: {
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Check for and store identity cookie if present
      this.handleIdentityCookie(response);

      return await response.json();
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  /**
   * Record a view for a post
   */
  async recordView(viewData: ViewRequest): Promise<void> {
    try {
      const response = await fetch(`${this.baseUrl}/view`, {
        method: 'POST',
        credentials: 'include', // Include cookies in request
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(viewData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Check for and store identity cookie if present
      this.handleIdentityCookie(response);
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  async submitAdvertisingForm(adData: AdRequest): Promise<void> {
    try {
      const response = await fetch(`${this.baseUrl}/advertisor-form`, {
        method: 'POST',
        credentials: 'include', // Include cookies in request
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(adData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Check for and store identity cookie if present
      this.handleIdentityCookie(response);
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  async submitRequestModelForm(modelData: RequestModelRequest): Promise<void> {
    try {
      const response = await fetch(`${this.baseUrl}/request-model`, {
        method: 'POST',
        credentials: 'include', // Include cookies in request
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(modelData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Check for and store identity cookie if present
      this.handleIdentityCookie(response);
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  async submitCuratorForm(curatorData: CuratorRequest): Promise<void> {
    try {
      const response = await fetch(`${this.baseUrl}/curator`, {
        method: 'POST',
        credentials: 'include', // Include cookies in request
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(curatorData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Check for and store identity cookie if present
      this.handleIdentityCookie(response);
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  async exchangeToken(idToken: string) {
    const response = await fetch(`${this.baseUrl}/auth/exchange`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ idToken }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to exchange token');
    }
  }

  async getTelegramSubscriptionStatus(): Promise<TelegramSubscriptionStatus> {
    try {
      const response = await fetch(`${this.baseUrl}/telegram/subscription`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  /**
   * Get a telegram bot start link for the current user
   */
  async getTelegramStartLink(): Promise<string> {
    try {
      const response = await fetch(`${this.baseUrl}/telegram/start-link`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: TelegramStartLink = await response.json();
      return data.start_link;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  /**
   * Get list of creators the user follows
   */
  async getFollows(): Promise<string[]> {
    try {
      const response = await fetch(`${this.baseUrl}/follows`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: UserFollows = await response.json();
      return data.follows || [];
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // Fix the follow interaction method to match backend expectations
  async recordFollowCreatorInteraction(creatorId: string, isFollow: boolean) {
    const response = await fetch(`${this.baseUrl}/follow`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        creator_id: creatorId,
        action: isFollow ? 'follow' : 'unfollow'
      }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to record follow interaction');
    }
  }

  async recordLikeInteraction(creatorId: string, isLike: boolean) {
    const response = await fetch(`${this.baseUrl}/like`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ creator_id: creatorId, action: isLike ? 'like' : 'unlike', }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to exchange token');
    }
  }

  async subscribeToNotifications(data: NotificationSubscription): Promise<void> {
    try {
      const response = await fetch(`${this.baseUrl}/notifications/subscribe`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      this.handleIdentityCookie(response);
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  /**
   * Handle storing the identity cookie from response headers
   */
  private handleIdentityCookie(response: Response): void {
    const cookies = response.headers.get('set-cookie');
    if (cookies) {
      cookies.split(',').forEach(cookie => {
        if (cookie.includes('identity_key')) {
          document.cookie = this.formatCookie(cookie);
        }
      });
    }
  }

  /**
   * Format cookie string with domain and security settings
   */
  private formatCookie(cookie: string): string {
    // Remove any existing domain
    let formatted = cookie.replace(/Domain=[^;]+;/, '');

    // Add our domain
    formatted += `; Domain=${this.cookieDomain}`;

    // Ensure cookie is secure and SameSite=Strict
    if (!formatted.includes('Secure')) {
      formatted += '; Secure';
    }
    if (!formatted.includes('SameSite')) {
      formatted += '; SameSite=Strict';
    }

    return formatted;
  }

  /**
   * Handle errors consistently
   */
  private handleError(error: unknown): void {
    const errorObject = error instanceof Error ? error : new Error(String(error));
    this.onError(errorObject);
  }

  /**
   * Get the current identity key from cookies
   */
  getIdentityKey(): string | null {
    const match = document.cookie.match(/identity_key=([^;]+)/);
    return match ? match[1] : null;
  }

  /**
   * Check if client has an identity key
   */
  hasIdentityKey(): boolean {
    return this.getIdentityKey() !== null;
  }
}

export type { Post, SearchResult, ViewRequest, FeedClientConfig };
export { FeedClient as ApiClient };
